.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.title {
  font-size: 50px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-bottom: 30px;
  font-family: Arial, sans-serif;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.subtitle {
  font-size: 38px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: Arial, sans-serif;  
}


.subtitle-uni {
  font-size: 30px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-bottom: 30px;
  font-family: Arial, sans-serif;  
}

.description {
  font-size: 15px;
  justify-content: center;
  align-items: center;  
  margin-bottom: 25px;
  font-family: Arial, sans-serif;  
  font-weight: normal;
}

.logo {
  height: 140px;
  margin-bottom: 30px;
}

.opening-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
}

.table th {
  background-color: #f0f0f0;
  font-weight: bold;
}

.table tbody tr:hover {
  background-color: #f9f9f9;
  cursor: pointer;
}

.table tbody tr.selected {
  background-color: #dd5050;
}


.form {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.form input[type="text"],
.form input[type="number"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}

.form button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-update {
  background-color: #28a745;
}

.btn-delete {
  background-color: #dc3545;
}

.btn-update,
.btn-delete {
  padding: 6px 12px;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-update:hover,
.btn-delete:hover {
  opacity: 0.8;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.title-container {
  text-align: left;
}

.universities-section {
  margin-bottom: 40px;
  margin-top: 50px;
}

.universities-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.divider {
  border: none;
  border-top: 1px solid #ccc;
  margin: 40px 0;
}


.students-section {
  margin-bottom: 40px;
}

.students-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.professional-certificates-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.professional-equivalences-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.companies-section {
  margin-bottom: 40px;
}

.companies-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.buttontab {
  background-color: black;
  color: white;
  padding: 15px 30px;
  margin-right: 20px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  border: none;
}

.buttontab.active {
  background-color: grey;
  color: white;
}

.json-section {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  white-space: pre-wrap;
  font-family: monospace;
}

/* General styles for lines in table actions */
.line {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

/* General styles for table actions container */
.table-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

/* Styles for each line within the table actions container */
.table-actions .line {
  flex-basis: 100%;
  display: flex;
  justify-content: center;
}

/* Additional styles for forms within each line */
.table-actions .line form.form {
  margin-right: 10px;
}

/* Styles for input fields */
.table-actions .line input[type="number"],
.table-actions .line input[type="date"] {
  margin-right: 10px;
}

/* Styles for buttons */
.table-actions .line button {
  margin-right: 10px;
}

/* Company Portal Styles */
.input-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
}

.input-container2 {  
  justify-content: center;
  margin-bottom: 10px;
}

.input-container .input-group {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-left: 10px;
}

.input-container label {
  margin-right: 10px;
}

.input-container p {
  margin: 0;
}

.section {
  margin-bottom: 40px;
}

/* Blockchain Integration V2 Styles */
.Json-resource-container {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  white-space: pre-wrap;
  font-family: monospace;
}

.side-by-side {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.side-by-side2 {
  display: inline-block;
  margin-left: 5px;
}

.side-by-side-bnumber {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.square {
  width: 70px;
  height: 70px;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  margin-left: 30px;
}

.small-heading {
  font-size: small;
}
.red {
  color: red;
}
.green {
  color: green;
}

.container-bch {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55vh; 
}

/*Certification Manager */

.container-CertificationManager {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.row-CertificationManager {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.col-CertificationManager {
  margin-right: 20px;
  align-self: flex-start;
}

.label-CertificationManager {
  font-weight: bold;
}

.select-input-CertificationManager {
  width: 200px;
}

.add-button-CertificationManager {
  margin-top: 10px;
}

.frame {
  background-color: #f2f2f2;
  border: 3px solid #007bff;
  padding: 10px;
  margin-top: 30px;
  border-radius: 6px;
}

.frame-content {
  background-color: #fff;
  padding: 4px;
  border-radius: 6px;
  box-shadow: 0 2px 4px #007bff;
}

.red-status {
  color: red;
}
.green-status {
  color: green;
}

